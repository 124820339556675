import { isBeta } from './sessionStorage'

const productionSearchPath = 'search'
const betaSearchPath = 'betaSearch'

export function isValidSearchPath(path) {
  return path === productionSearchPath || path === betaSearchPath
}

export function getProductionSearchPath() {
  return productionSearchPath
}

export function getBetaSearchPath() {
  return betaSearchPath
}

export function getSearchPath() {
  return isBeta() ? betaSearchPath : productionSearchPath
}

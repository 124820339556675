const PAGES_IN_VUE = [
  // static pages
  '/',
  '/About',
  '/Annotations',
  '/AnnotationsSubmission',
  '/API-case-studies',
  '/ArticleStatusMonitor',
  '/ContactUs',
  '/Copyright',
  '/downloads/manuscripts',
  '/downloads/openaccess',
  '/downloads/preprints',
  '/Funders',
  '/Governance',
  '/GristAPI',
  '/GristAPI/dataFields',
  '/Help',
  '/JaxWs',
  '/BecomeFunder',
  '/Outreach',
  '/Preprints',
  '/PrivacyNoticeForEPMC',
  '/Roadmap',
  '/RssFeeds',
  '/searchsyntax',
  // dynamic pages
  '/accounts/ChangeAlertsEmail',
  '/accounts/notifications/activation',
  '/unsubscribe/SavedSearches',
  '/accounts/SavedAlerts',
  '/article',
  '/search',
  '/authors',
  '/grantfinder',
  '/funder-dashboard',
]

export const isPageInVue = PAGES_IN_VUE.find((page) => {
  const lowerCasePathname = location.pathname.toLowerCase()
  const lowerCasePage = page.toLowerCase()

  if (lowerCasePathname === lowerCasePage) {
    return true
  } else if (lowerCasePathname.startsWith(lowerCasePage)) {
    if (['/', '?', '#'].includes(lowerCasePathname[lowerCasePage.length])) {
      return true
    }
  }

  return false
})

// dynamic pages
const Accounts = () => import('./pages/account/Index')
const Article = () => import('./pages/Article')
const AuthorProfile = () => import('./pages/AuthorProfile')
const FunderDashboard = () => import('./pages/FunderDashboard')
const GrantFinder = () => import('./pages/grant-finder/Index')
const Search = () => import('./pages/Search')
const BetaSearch = () => import('./pages/BetaSearch')

// static pages
const About = () => import('./pages/static/about/Index')
const AboutArticleStatusMonitor = () =>
  import('./pages/static/AboutArticleStatusMonitor')
const Annotations = () => import('./pages/static/tools/Annotations')
const AnnotationSubmissionService = () =>
  import('./pages/static/tools/AnnotationSubmissionService')
const ArticleStatusMonitor = () =>
  import('./pages/static/tools/ArticleStatusMonitor')
const ContactUs = () => import('./pages/static/help/ContactUs')
const Copyright = () => import('./pages/static/Copyright')
const Downloads = () => import('./pages/static/developers/downloads/Index')
const Feedback = () => import('./pages/static/Feedback')
const Funders = () => import('./pages/static/about/Funders')
const Governance = () => import('./pages/static/about/Governance')
const GristAPI = () => import('./pages/static/developers/grist-api/Index')
const Help = () => import('./pages/static/help/Help')
const Home = () => import('./pages/static/Home')
const BecomeFunder = () => import('./pages/static/about/BecomeFunder')
const Outreach = () => import('./pages/static/about/Outreach')
const Preprints = () => import('./pages/static/about/Preprints')
const PrivacyNotice = () => import('./pages/static/PrivacyNotice')
const Roadmap = () => import('./pages/static/about/Roadmap')
const RssFeeds = () => import('./pages/static/tools/RssFeeds')
const SearchSyntax = () => import('./pages/static/help/SearchSyntax')
const UnsubscribeAlert = () => import('./pages/account/UnsubscribeAlert')
const UseCases = () => import('./pages/static/developers/use-cases/Index')
const VerifyAlertsEmail = () => import('./pages/account/VerifyAlertsEmail')
const JaxWs = () => import('./pages/static/developers/soap/JaxWs')

// templates
const Footer = () => import('./templates/Footer')
const SearchPanel = () => import('./templates/SearchPanel')

// maintenance
const MSSMaintenance = () => import('./pages/static/MSSMaintenance')

export default {
  mode: 'history',
  routes: [
    // dynamic pages
    {
      path: '/',
      name: 'home',
      component: Home,
      props: true,
    },
    {
      path: '/accounts',
      name: 'accounts',
      component: Accounts,
      children: [
        {
          path: 'SavedAlerts',
          name: 'savedAlerts',
          component: require('./pages/account/SavedAlerts').default,
          props: (route) => ({
            status: route.query.status,
            email: route.query.email,
            alertName: route.query.alertName,
            alertQuery: route.query.alertQuery,
          }),
        },
        {
          path: 'ChangeAlertsEmail',
          name: 'changeAlertsEmail',
          component: require('./pages/account/ChangeAlertsEmail').default,
          props: (route) => ({
            status: route.query.status,
          }),
        },
      ],
    },
    {
      path: '/accounts/notifications/activation',
      name: 'verifyAlertsEmail',
      component: VerifyAlertsEmail,
      props: (route) => ({
        code: route.query.code,
        email: route.query.email,
      }),
    },
    {
      path: '/unsubscribe/SavedSearches',
      name: 'unsubscribeAlert',
      component: UnsubscribeAlert,
      props: (route) => ({
        code: route.query.code,
        alert: route.query.alert,
        email: route.query.email,
      }),
    },
    {
      path: '/article/:source/:id/:bookid',
      name: 'book',
      component: Article,
      props: true,
    },
    {
      path: '/article/:source/:id',
      name: 'article',
      component: Article,
      props: true,
    },
    {
      path: '/authors/:orcid',
      name: 'authorProfile',
      component: AuthorProfile,
      props: true,
    },
    {
      path: '/funder-dashboard',
      name: 'funderDashboard',
      component: FunderDashboard,
      props: (route) => ({
        funderName: route.query.funderName,
        datesStr: route.query.datesStr,
        tabName: route.query.tabName,
      }),
    },
    {
      path: '/grantfinder',
      name: 'grantFinder',
      component: GrantFinder,
      children: [
        {
          path: '',
          name: 'grantFinderForm',
          component: require('./pages/grant-finder/GrantFinderForm').default,
        },
        {
          path: 'results',
          name: 'grantSearch',
          component: require('./pages/grant-finder/GrantSearch').default,
          props: (route) => ({
            kw: route.query.kw,
            aff: route.query.aff,
            pi: route.query.pi,
            pi_id: route.query.pi_id,
            ga: route.query.ga,
            gid: route.query.gid,
            current: route.query.current,
            cat: route.query.cat,
            page: route.query.page,
          }),
        },
        {
          path: 'grantdetails',
          name: 'grantDetails',
          components: require('./pages/grant-finder/GrantDetails'),
        },
      ],
      // better to put props into the grantDetails child
      props: (route) => ({
        query: route.query.query,
        cat: route.query.cat,
      }),
    },
    {
      path: '/search',
      name: 'search',
      component: Search,
      props: (route) => ({
        query: route.query.query,
        page: route.query.page,
        sortBy: route.query.sortBy,
        synonym: route.query.synonym,
        action: route.query.action,
        maxPageJump: route.query.maxPageJump,
      }),
    },
    {
      path: '/betaSearch',
      name: 'betaSearch',
      component: BetaSearch,
      props: (route) => ({
        query: route.query.query,
        page: route.query.page,
        sortBy: route.query.sortBy,
        synonym: route.query.synonym,
        action: route.query.action,
        maxPageJump: route.query.maxPageJump,
      }),
    },
    // static pages
    {
      path: '/About',
      name: 'about',
      component: About,
    },
    {
      path: '/AboutArticleStatusMonitor',
      name: 'aboutArticleStatusMonitor',
      component: AboutArticleStatusMonitor,
    },
    {
      path: '/Annotations',
      name: 'annotations',
      component: Annotations,
    },
    {
      path: '/AnnotationsSubmission',
      name: 'annotations',
      component: AnnotationSubmissionService,
    },
    {
      path: '/API-case-studies',
      name: 'useCases',
      component: UseCases,
      children: [
        {
          path: '',
          name: 'useCasesIndex',
          component: require('./pages/static/developers/use-cases/UseCases')
            .default,
        },
      ],
    },
    {
      path: '/ArticleStatusMonitor',
      name: 'articleStatusMonitor',
      component: ArticleStatusMonitor,
    },
    {
      path: '/ContactUs',
      name: 'contactUs',
      component: ContactUs,
    },
    {
      path: '/Copyright',
      name: 'copyright',
      component: Copyright,
    },
    {
      path: '/downloads',
      name: 'downloads',
      component: Downloads,
      children: [
        {
          path: '',
          name: 'downloadsIndex',
          component: require('./pages/static/developers/downloads/Downloads')
            .default,
        },
        {
          path: 'manuscripts',
          name: 'manuscriptsDownloads',
          component: require('./pages/static/developers/downloads/Manuscripts')
            .default,
        },
        {
          path: 'openaccess',
          name: 'openAccessDownloads',
          component: require('./pages/static/developers/downloads/OpenAccess')
            .default,
        },
        {
          path: 'preprints',
          name: 'preprintsDownloads',
          component: require('./pages/static/developers/downloads/Preprints')
            .default,
        },
      ],
    },
    {
      path: '/feedback',
      name: 'feedback',
      component: Feedback,
      props: (route) => ({
        url: route.query.url,
      }),
    },
    {
      path: '/Funders',
      name: 'funders',
      component: Funders,
    },
    {
      path: '/Governance',
      name: 'governance',
      component: Governance,
    },
    {
      path: '/GristAPI',
      name: 'gristAPI',
      component: GristAPI,
      children: [
        {
          path: '',
          name: 'gristAPIIndex',
          component: require('./pages/static/developers/grist-api/GristAPI')
            .default,
        },
        {
          path: 'dataFields',
          name: 'dataFields',
          component: require('./pages/static/developers/grist-api/DataFields')
            .default,
        },
      ],
    },
    {
      path: '/Help',
      name: 'help',
      component: Help,
    },
    {
      path: '/JaxWs',
      name: 'jaxWs',
      component: JaxWs,
    },
    {
      path: '/BecomeFunder',
      name: 'becomeFunder',
      component: BecomeFunder,
    },
    {
      path: '/Outreach',
      name: 'outreach',
      component: Outreach,
    },
    {
      path: '/PrivacyNoticeForEPMC',
      name: 'privacyNotice',
      component: PrivacyNotice,
      props: (route) => ({
        type: route.query.type,
      }),
    },
    {
      path: '/Preprints',
      name: 'preprints',
      component: Preprints,
    },
    {
      path: '/Roadmap',
      name: 'roadmap',
      component: Roadmap,
    },
    {
      path: '/RssFeeds',
      name: 'rssFeeds',
      component: RssFeeds,
    },
    {
      path: '/searchsyntax',
      name: 'searchSyntax',
      component: SearchSyntax,
    },
    // templates
    {
      path: '/footer',
      name: 'footer',
      component: Footer,
    },
    {
      path: '/search-panel',
      name: 'searchPanel',
      component: SearchPanel,
    },
    // maintenance
    {
      path: '/MSSMaintenance',
      name: 'MSSMaintenance',
      component: MSSMaintenance,
    },
  ],
  scrollBehavior: function (to) {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  },
}
